// import { FaArrowRightLong } from "react-icons/fa6";
import "./Services.css";
import { Link } from "react-router-dom";


function Services() {
    return(
        <div id="Services" className="section">
            <h4>Services</h4>
            <ul>
                <li>Website development</li>
                <li>Website design</li>
                <li>Website maintenance</li>
            </ul>
            <Link to="/services">
                <button className="extra">Learn more</button>
            </Link>
        </div>
    )
}

export default Services;
