import "./ServicesFull.css";
import { FaPlus } from "react-icons/fa";
import coding from ".//images/icons/coding.svg";
import consult from ".//images/icons/consult.svg";
import deploy from ".//images/icons/deploy.svg";
import emailjs from '@emailjs/browser';
import {useRef, useState} from "react"


function HowItWorks() {
    return(
        <div id="HowItWorks">
            <h2>How it works</h2>
            <div className="step">
                <div className="container">
                    <h5>Free Consultation</h5>
                    <div className="description">
                        We discuss your business, your goals, what you would like your website to do, and what potential third-party services we might need, what assets we might need, potential websites that you would like us to use as inspiration, and when you would need the website by.

                        <br></br>
                        <br></br>
                        I will then design a wireframe that would show a general idea of what the website will look like and how it will function. I can also, then, give a quote on how much the service will be.
                    </div>
                </div>
                <img
                    className="consult"
                    src={consult}
                    alt="Consultation icon" />
            </div>
            <div className="step switch">
                <div className="container">
                    <h5>Build</h5>
                    <div className="description">
                        Once you decide you would like to work together, we will then start the build phase. After the first week, you will have access to a temporary url which you can use to interact with your website and to see the progress that has been made so far. In addition, you will be able to contact me via my email and we will have weekly zoom meetings in which we can discuss the progress, bounce ideas, and give feedback.
                    </div>
                </div>
                <div className='center'>
                    <img
                        className="coding"
                        src={coding}
                    alt="icon of someone coding"
                    />
                </div>
            </div>
            <div className="step">
                <div className="container">
                    <h5>Deployment</h5>
                    <div className="description">Your website goes live! For the next week, we will watch your site and ensure that everything works as it should!</div>
                </div>

                <img
                    src={deploy}
                    alt="icon of deployment celebration"
                    className="deploy"
                 />
            </div>
        </div>
    )
}

function WhatIsIncluded() {
    const packages = [
        "Free Consultation",
        "ASGI Approved, Responsive Design",
        "Basic SEO setup",
        "Website Document",
        "Content Writing",
        "Weekly Update Meetings",
        "Website maintenance for up to one year"
    ]
    return(
        <div id="WhatIsIncluded">
            <div>
                <h2>What's Included:</h2>
                <ul>
                    {packages.map((unit => {
                        return(
                            <li><FaPlus className="plus-btn" size="15px"/> {unit}</li>
                        )
                    }))}
                </ul>
            </div>
        </div>
    )
}


function ServicesFull() {
    const [messageSent, setMessageSent] = useState(false);
    const [messageSuccess, setMessageSuccess] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [message, setMessage] = useState('');

    const form = useRef();
    const sendEmail = (e)=> {
        e.preventDefault();

        emailjs
            .sendForm("service_1nva6wa", "template_63rprtk", form.current, {
                publicKey: 'N3ZdWAGwzdm48JIGo'
            })
            .then(
                () => {
                    setMessageSent(true);
                    setMessageSuccess(true);
                    setName('');
                    setEmail('');
                    setNumber('');
                    setMessage('');
                },
            (error) => {
                console.log("Message failed to send: ", error)
                setMessageSent(true);
            },
        );
    }

    return(
        <div id="ServicesFull">
            <meta className="description" content="Explore what it would be like to work together while we make your ideas come to life!" />
            {/* Services Full */}
            <h1>Web Development Services</h1>
            <div className="description">
            Explore what it would be like to work together while we make your ideas
            come to life!
            </div>
            <HowItWorks />
            <WhatIsIncluded />
            <div id="WorkTogether">
                <h2>Interested in working together?</h2>
                <div className="container">
                    {!messageSent &&
                        <form ref={form} onSubmit={sendEmail} className="work-together-form">
                            <label htmlFor="name">Full name:</label>
                            <input
                                value={name}
                                name="name"
                                onChange={(e) => setName(e.target.value)}
                            >
                            </input>
                            <label htmlFor="email">Email:</label>
                            <input
                                value={email}
                                name="email"
                                onChange={(e) => setEmail(e.target.value)}
                            >
                            </input>
                            <label htmlFor="phone">Phone number &#40;optional&#41;:</label>
                            <input
                                value={number}
                                name="number"
                                onChange={(e) => setNumber(e.target.value)}
                            >
                            </input>
                            <label>Tell me about your business...</label>
                            <textarea
                                value={message}
                                name="message"
                                onChange={(e) => setMessage(e.target.value)}
                            >
                            </textarea>
                            <button className="extra">Submit</button>
                        </form>
                    }
                </div>
                {messageSent &&
                    <div className="message">
                        {messageSuccess &&
                            <div className="success">
                                Message sent! I will reach out to you within the next 24 hours!
                            </div>
                        }
                        {!messageSuccess &&
                            <div className="fail">
                                Message failed to send. Please refresh and try again.
                            </div>
                        }
                    </div>
                }
            </div>


        </div>
    )
}

export default ServicesFull;
