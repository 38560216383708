import './ProjectsFull.css';
import Project from './Project';
import ProjectsList from './ProjectsList';

function ProjectsFull() {
    return(
        <div id="ProjectsFull">
            <div className="header">
                <h1 className="title">PROJECTS</h1>
            </div>
            <div className="projects-container">
                {ProjectsList.map((project) => {
                    console.log("project: ", project)
                    return(
                        <>
                        <Project
                            date={project.dates}
                            description={project.description}
                            title={project.title}
                            url={project.url}
                            tech={project.tech}
                            img={project.img}
                        />
                        </>
                    )
                })}
            </div>
        </div>
    )
}

export default ProjectsFull;
