import "./Contact.css";
import emailjs from '@emailjs/browser';
import {useRef, useState} from "react"

function Contact() {
    const [messageSent, setMessageSent] = useState(false);
    const [messageSuccess, setMessageSuccess] = useState(false);

    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm("service_1nva6wa", 'template_00slkk9', form.current, {
                publicKey: 'N3ZdWAGwzdm48JIGo'
            })
            .then(
                () => {
                    console.log("email sent successfully");
                    setMessageSent(true);
                    setMessageSuccess(true);
                },
            (error) => {
                console.log("Message failed to send: ", error)
                setMessageSent(true);
            },
        );
    };
    return(
        <div id="Contact" className="section">
        { !messageSent &&
        <>


            <h4>Contact me</h4>
            <form ref={form} onSubmit={sendEmail}>
                <label for="name">Full name:</label>
                <input name="name" />
                <label for="email">Email:</label>
                <input name="email" />
                <label for="message">Message:</label>
                <textarea
                    name="message"
                    rows="3"
                />
                <button className="extra">Submit</button>
            </form>
            </>

        }

        {messageSent &&
        <div className="message">
            {messageSuccess &&
                <div className="success">
                    Message sent! I will reach out to you within the next 24 hours!
                </div>
            }
            {!messageSuccess &&
                <div className="fail">
                    Message failed to send. Please refresh and try again.
                </div>
            }
        </div>
        }
        </div>
    )
}

export default Contact;
