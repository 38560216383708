import bmdetail from ".//images/bmdetail.png";
import apex from ".//images/apex.png";
import gabe from ".//images/gabe.png";
import pp from ".//images/popcornpicks.png";


const ProjectsList = [
    {
        url: "https://gabrielclements.com/",
        dates: "August 2024",
        title: "Web Developer, Portfolio - Gabriel Clements",
        description:
            "Collaborated with Gabriel Clements to build and design portfolio. Integrated headless CMS backend to allow for independent content upload.",
        tech: ["JavaScript", "Strapi", "MySQL", "Ubuntu"],
        img: gabe
    },
    {
        url: "https://apexoverhead.com/",
        dates: "June 2024",
        title: "Web Developer, Apex Overhead - Jesus Martinez",
        description:
            "A user-friendly site to showcase ApexOverhead's business and services. Customer reviews function has been created, so customers can leave reviews directly on the website.",
        tech: ["JavaScript", "Supabase", "MySQL", "React"],
        img: apex
    },
    {
        url: "https://bmdetailservices.com/",
        dates: "September 2024",
        title: "Web Developer, BmDetailServices - Brayan Martinez",
        description:
            "Leveraged Google Business API to display customer reviews. Integrated Calendly widget to automate appointment scheduling, accounting for different appointment types, different time blocks, and package customization, optimizing communication and scheduling for business owner",
        tech: ["JavaScript", "React"],
        img: bmdetail
    },
    {
        url: "https://popcorn-picks.gitlab.io/popcorn-picks",
        dates: "February 2024",
        title: "Full stack developer, PopcornPicks",
        description:
            "A movie reviewing website that helps you discover new movies and connect with other like-minded movie enthusiasts. Create your own profile and explore!",
        tech: ["JavaScript", "FastAPI", "PostgreSQL"],
        img: pp
    }
    // {
    //     url: "https://gitlab.com/amvillap/oddjobs",
    //     dates: "February 2024",
    //     title: "Full Stack Developer, OddJobs",
    //     description:
    //         "Not feeling up to the task? Pay someone to do it for you! Need to make some quick cash? Sign up to help someone out in need! Low commitment, high transparency, OddJobs is the go to platform to find and post odd jobs for people in your area! It's a great way to connect with your community, while making some money along the way!",
    //     tech: ["JavaScript", "FastAPI", "PostgreSQL"]
    // }

]

export default ProjectsList;
